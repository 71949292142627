import React, { useState, useEffect } from "react";
import queryString from "query-string";
import * as styles from "./batch.module.css";
import axios from "axios";
import { navigate, Link } from "gatsby";
import { isNaN as _isNaN } from "lodash";
import { Formik } from "formik";
import Spinner from "../../elements/spinner/Spinner";
import { transformBatchInfoSchemaData } from "../../providers/contentstack/modular-blocks/batch_info";
import LazyLoadingImage from "../lazy-loading-image";

const inputRegex = /^[a-zA-Z0-9\-_\.~:/\s?#\[\]@!$&'()*+,;=]+$/;

const BatchFinderBlock = (props) => {
  let parsed = queryString.parse(props.location.search);

  const controller = new AbortController(); // can this be invoked in actual axios call?

  // the types i initialized with are intentional but its fragile; refactor
  const [batchData, setBatchData] = useState(undefined);
  const [dataProcessing, setDataProcessing] = useState(false);
  const [dataEmpty, setDataEmpty] = useState(undefined);

  const fetchBatches = async (id) => {
    setDataProcessing(false);
    setBatchData(undefined);
    setDataEmpty(undefined);
    const contentType = "batch_info";
    const url = `${"https://cdn.contentstack.io"}/v3/content_types/${contentType}/entries?environment=${
      process.env.GHUB_CONTENTSTACK_ENVIRONMENT
    }`;
    const filterById = `&query={"batch_id": "${id || parsed.id}"}`;
    const endpoint = url + filterById;
    if (id || parsed.id) {
      setDataProcessing(true);
      // the call to axios can be moved to a helper
      await axios
        .get(endpoint, {
          signal: controller.signal,
          headers: {
            access_token: process.env.GHUB_CONTENTSTACK_DELIVERY_TOKEN,
            api_key: process.env.GHUB_CONTENTSTACK_STACK_KEY,
          },
        })
        .then((res) => {
          setDataProcessing(false);
          if (res.data.entries.length > 0) {
            res.data.entries[0] = transformBatchInfoSchemaData(
              res.data.entries[0]
            );
            setBatchData(res.data);
          } else {
            setDataEmpty(true);
          }
        })
        .catch(function (thrown) {
          // console.log(thrown.message);
        });
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";

    const dateObject = new Date(dateString);

    if (!isNaN(dateObject.getTime())) {
      const formattedDate = new Intl.DateTimeFormat("en-US", {
        timeZone: "UTC",
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(dateObject);

      // Replace slashes with hyphens
      return formattedDate.replace(/\//g, "-");
    } else {
      return dateString;
    }
  };

  useEffect(() => {
    if (!batchData) {
      if (props.batchData) {
        setBatchData(props.batchData);
      } else {
        fetchBatches();
      }
    }
    return () => {
      controller.abort();
    };
  }, []);

  const batch =
    batchData && batchData.entries && batchData.entries.length > 0
      ? batchData.entries[0]
      : undefined;

  // i think i caught every permutation of rendering scenarios
  let imageSrc = "";
  let alt = "";
  switch (batch?.brand) {
    case "Classix":
      imageSrc =
        "https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/bltd227438861b705d0/655d11cfd59549e87fb642f3/classix-logo.png";
      alt = "Classix";
      break;
    case "Hedy":
      imageSrc =
        "https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/blt6fbf3cc3d037d28f/655d11cf7c56dd74dea5e476/hedy-edibles.png";
      alt = "Hedy";
      break;
    case "Amber":
      imageSrc =
        "https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/blt4221458ca2ad321c/655d11cf04116d828029d52c/amber-logo.png";
      alt = "Amber";
      break;
    case "Press":
      imageSrc =
        "https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/blta2ff89423e1bf353/655d11cf7539114d27d039e7/press-logo.png";
      alt = "Press";
      break;
    case "Seed & Strain":
      imageSrc =
        "https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/blt89f094bd758de2ef/655d11cf7c56dd6581a5e47a/seed-and-strain-logo.png";
      alt = "Seed & Strain";
      break;
    case "Triple Seven":
      imageSrc =
        "https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/blt8bbcb809ce24abc5/655d11cfd41131f19c246c46/triple-seven-logo.png";
      alt = "Triple Seven";
      break;
    default:
      imageSrc =
        "https://images.contentstack.io/v3/assets/bltc2a866f5b4786ae7/blt59727e4d03d7a4fc/651b2380ecf48b0b6bc37987/TCC_Logo_-_Image_Only.png";
      alt = "Cannabist Company Product";
      break;
  }
  return (
    <div id="batch-finder">
      <div id={styles.hero__image}>
        <h1>{`Batch Finder`}</h1>
      </div>
      {batch === undefined &&
        dataEmpty === undefined &&
        dataProcessing === true && (
          <div id={styles.text__container}>
            <Spinner />
          </div>
        )}
      {parsed.id && !dataProcessing && !inputRegex.test(parsed.id) && (
        <div id={styles.text__container}>
          <h3>Batch ID invalid.</h3>
        </div>
      )}
      {parsed.id && inputRegex.test(parsed.id) && !batch && !!dataEmpty && (
        <div id={styles.text__container}>
          <h3>No batch with that ID found.</h3>
        </div>
      )}
      {parsed.id && batch && (
        <div id={styles.text__container}>
          {batch.product_name && (
            <div className={styles.text__section}>
              {/* <img src={imageSrc} alt={alt} width="200" /> */}
              <LazyLoadingImage src={imageSrc} alt={alt} width="200" />
              <h3 id={styles.product__name}>
                <strong>Product Name: {batch.product_name}</strong>
              </h3>
            </div>
          )}
          <div className={styles.text__section}>
            {!batch.product_name && (
              <h3>
                <strong>{batch.title}</strong>
              </h3>
            )}
            <h3>
              <strong>Batch ID:</strong> {batch.batch_id}
            </h3>
            <ul>
              {/*<li>
                  <strong>Created at:</strong> {batch.created_at}
        </li> */}
              <li>
                <strong>Product type:</strong> {batch.product_type}
              </li>
            </ul>
          </div>
          {/*<a
              href={batch.test_results_coa_link}
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt={`Test Results COA Link`}
                width="50%"
                src={batch.test_results_coa_link}
              />
        </a>*/}
          <div className={styles.text__section}>
            <h3>Cultivation Details</h3>
            <ul>
              <li>
                <strong>Company name:</strong>{" "}
                {batch.cultivation_details.cultivation_company_name}
              </li>
              {batch.cultivation_details.cultivation_date_of_harvest && (
                <li>
                  <strong>Date of harvest:</strong>{" "}
                  {formatDate(
                    batch.cultivation_details.cultivation_date_of_harvest
                  )}
                </li>
              )}
              <li>
                <strong>License number:</strong>{" "}
                {batch.cultivation_details.cultivation_license_number}
              </li>
              <li>
                <strong>Strain:</strong>{" "}
                {batch.cultivation_details.cultivation_strain}
              </li>
            </ul>
          </div>
          {batch.product_type !== "Flower" && (
            <div className={styles.text__section}>
              <h3>Manufacturing Details</h3>
              <ul>
                <li>
                  <strong>Company name:</strong>{" "}
                  {batch.manufacturing_details.manufacturing_company_name}
                </li>
                <li>
                  <strong>Date of manufacture:</strong>{" "}
                  {
                    batch.manufacturing_details
                      .manufacturing_date_of_manufacture
                  }
                </li>
                <li>
                  <strong>License number:</strong>{" "}
                  {batch.manufacturing_details.manufacturing_license_number}
                </li>
                <li>
                  <strong>Method of extraction:</strong>{" "}
                  {
                    batch.manufacturing_details
                      .manufacturing_method_of_extraction
                  }
                </li>
              </ul>
            </div>
          )}
          <div className={styles.text__section}>
            <h3>Distribution Information</h3>
            <ul>
              <li>
                <strong>Company name:</strong>{" "}
                {batch.distribution_information.distro_company_name}
              </li>
              <li>
                <strong>License number:</strong>{" "}
                {batch.distribution_information.distro_license_number}
              </li>
            </ul>
            <div id={styles.link__section}>
              <h3>Test Results:</h3>
              <ul>
                {batch.test_results_coa_link?.map((url, index) => (
                  <li key={index}>
                    <a href={url} target="_blank" rel="noreferrer">
                      Click here for certificate of analysis
                    </a>
                  </li>
                ))}
              </ul>{" "}
            </div>

            <div id={styles.asterisk}>
              *Using marijuana during pregnancy could cause birth defects or
              other health issues to your unborn child*
            </div>
            {batch.market === "AZ" && (
              <div className={styles.additional__licenses}>
                <Link to="/arizona-active-licenses">
                  Click here for additional licenses
                </Link>
              </div>
            )}
          </div>
          {/*<br />
            <Link to={`/batch`}>Go back</Link>
            <br />
      */}
        </div>
      )}
      {!parsed.id && !dataProcessing && typeof window === "object" && (
        <div id={styles.text__container}>
          <h3 id={styles.batch__header}>
            Enter a batch ID to search for a batch.
          </h3>
          <Formik // we're consistent enough w/ Formik pattern that it should be abstracted
            initialValues={{
              id: "",
            }}
            validate={(values) => {
              const errors = {};

              // definitely move errors to a helper
              if (!values.id) {
                errors.id = "Batch ID is required";
              } else if (!inputRegex.test(values.id)) {
                errors.id = "Invalid batch ID";
              }

              return errors;
            }}
            // probably require user to provide bespoke onSubmit even w/ abstraction
            onSubmit={(values, { setSubmitting, setStatus }) => {
              setBatchData(undefined);
              fetchBatches(values.id);
              navigate(`/batch?id=${values.id}`);
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => {
              if (isSubmitting) {
                return <Spinner />;
              }
              return (
                <div>
                  <div>
                    <form onSubmit={handleSubmit}>
                      <div>
                        <div>
                          <input // move all inputs to a helper
                            required
                            name="id"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.id}
                            placeholder="Batch ID"
                          />
                          {errors.id && touched.id && errors.id && (
                            <span className={styles.batch__errors}>
                              {errors.id && touched.id && errors.id}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className={styles.batch___button}>
                        {/* Need id Selector? */}
                        <button
                          id="batch-finder-submit"
                          disabled={isSubmitting}
                          type="submit"
                        >
                          {`Search`}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              );
            }}
          </Formik>
        </div>
      )}
    </div>
  );
};
export default BatchFinderBlock;
