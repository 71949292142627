import React from "react";

import BatchFinderBlock from "@col-care/common/blocks/batch-finder/BatchFinder";

export default function Batch(props) {

  return (
    <div>
      <BatchFinderBlock {...props} />  
    </div>
  );
};