import { string, object, bool, array } from "yup";
import md5 from "md5";
import { map as _map, filter as _filter, reduce as _reduce } from "lodash";

export let batchInfoSchema = object().shape({
  product_name: string().required(),
  market: string().nullable(),
  batch_id: string().required(),
  product_type: string().nullable(),
  cultivation_details: object().shape({
    cultivation_company_name: string().nullable(),
    cultivation_date_of_harvest: string().nullable(),
    cultivation_license_number: string().nullable(),
    cultivation_strain: string().nullable(),
  }),
  manufacturing_details: object().shape({
    manufacturing_company_name: string().nullable(),
    manufacturing_date_of_manufacture: string().nullable(),
    manufacturing_license_number: string().nullable(),
    manufacturing_method_of_extraction: string().nullable(),
  }),
  distribution_information: object().shape({
    distro_company_name: string().nullable(),
    distro_license_number: string().nullable(),
  }),
  test_results_coa_link: array().nullable(),
  __digest: string().required(),
});

export const transformBatchInfoSchemaData = (data) => {
  try {
    if (!data) {
      return null;
    }

    // freeze the object model; no new properties allowed
    let hydratedModel = Object.seal({
      product_name: null,
      market: null,
      batch_id: null,
      product_type: null,
      cultivation_details: {
        cultivation_company_name: null,
        cultivation_date_of_harvest: null,
        cultivation_license_number: null,
        cultivation_strain: null,
      },
      manufacturing_details: {
        manufacturing_company_name: null,
        manufacturing_date_of_manufacture: null,
        manufacturing_license_number: null,
        manufacturing_method_of_extraction: null,
      },
      distribution_information: {
        distro_company_name: null,
        distro_license_number: null,
      },
      test_results_coa_link: null,
      __digest: null,
    });

    // map the field names here
    hydratedModel.product_name = data.product_name;
    hydratedModel.market = data.market;
    hydratedModel.batch_id = data.batch_id;
    hydratedModel.product_type = data.product_type;

    if (data.cultivation_details) {
      hydratedModel.cultivation_details.cultivation_company_name =
        data.cultivation_details.cultivation_company_name;
      hydratedModel.cultivation_details.cultivation_date_of_harvest =
        data.cultivation_details.cultivation_date_of_harvest;
      hydratedModel.cultivation_details.cultivation_license_number =
        data.cultivation_details.cultivation_license_number;
      hydratedModel.cultivation_details.cultivation_strain =
        data.cultivation_details.cultivation_strain;
    }

    if (data.manufacturing_details) {
      hydratedModel.manufacturing_details.manufacturing_company_name =
        data.manufacturing_details.manufacturing_company_name;
      hydratedModel.manufacturing_details.manufacturing_date_of_manufacture =
        data.manufacturing_details.manufacturing_date_of_manufacture;
      hydratedModel.manufacturing_details.manufacturing_license_number =
        data.manufacturing_details.manufacturing_license_number;
      hydratedModel.manufacturing_details.manufacturing_method_of_extraction =
        data.manufacturing_details.manufacturing_method_of_extraction;
    }

    if (data.distribution_information) {
      hydratedModel.distribution_information.distro_company_name =
        data.distribution_information.distro_company_name;
      hydratedModel.distribution_information.distro_license_number =
        data.distribution_information.distro_license_number;
    }

    hydratedModel.test_results_coa_link = data.test_results_coa_link;

    // always add a digest to a modular block
    hydratedModel.__digest = md5(JSON.stringify(data));

    try {
      batchInfoSchema.validateSync(hydratedModel);
      return hydratedModel;
    } catch (err) {
      console.warn(err);
      return null;
    }
  } catch (err) {
    console.error(err);
  }
};
