// extracted by mini-css-extract-plugin
export var additional__licenses = "batch-module--additional__licenses--3b1d5";
export var asterisk = "batch-module--asterisk--258d7";
export var batch___button = "batch-module--batch___button--c94e5";
export var batch__errors = "batch-module--batch__errors--009c7";
export var batch__header = "batch-module--batch__header--21303";
export var hero__image = "batch-module--hero__image--4125b";
export var link__section = "batch-module--link__section--84862";
export var product__name = "batch-module--product__name--a48fc";
export var rdt_TableBody = "batch-module--rdt_TableBody--30523";
export var rdt_TableRow = "batch-module--rdt_TableRow--0f382";
export var text__container = "batch-module--text__container--52583";
export var text__section = "batch-module--text__section--f7632";